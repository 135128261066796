<template>
  <div class="page config">
    <div class="page__content">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="积分规则设置">
          <p>请在此设置店铺积分管理相关规则：</p>

          <a-form
            :form="form"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
          >
            <div class="card">
              <p class="card__title">积分通用规则</p>
            </div>

            <a-form-item label="积分有效期">
              <a-radio-group>
                <div class="card__content">
                  <div>
                    <a-radio :value="1">永久有效</a-radio>
                  </div>
                  <div>
                    <a-radio :value="2">从获得开始至</a-radio>
                    <div>
                      <a-select style="width: 120px">
                        <a-select-option value="jack"> 一年后 </a-select-option>
                        <a-select-option value="lucy"> Lucy </a-select-option>
                      </a-select>
                      <a-select style="width: 120px">
                        <a-select-option value="jack"> 一年后 </a-select-option>
                        <a-select-option value="lucy"> Lucy </a-select-option>
                      </a-select>
                      <span style="margin: 0 4px">月</span>
                      <a-select style="width: 120px">
                        <a-select-option value="jack"> 一年后 </a-select-option>
                        <a-select-option value="lucy"> Lucy </a-select-option>
                      </a-select>
                      <span style="margin: 0 4px">日</span>
                    </div>
                  </div>
                  <div style="color: red">
                    例如，设置未“明年的01月01日”，即是指用户今年获得的积分将在明年1月1日24:00:00失效
                  </div>
                  <div>
                    <a-radio :value="3">从获得积分当天起</a-radio>
                    <div>
                      <a-input-number style="width: 120px" />
                      <a-select style="width: 120px">
                        <a-select-option value="jack"> 一年后 </a-select-option>
                        <a-select-option value="lucy"> Lucy </a-select-option>
                      </a-select>
                      <span style="margin: 0 4px">内有效</span>
                    </div>
                  </div>
                </div>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="积分兑换比">
              <div>
                <a-select style="width: 120px">
                  <a-select-option value="jack"> 一年后 </a-select-option>
                  <a-select-option value="lucy"> Lucy </a-select-option>
                </a-select>
                <span style="margin: 0 4px">积分=1元RMB</span>
                <span style="margin: 0 4px; color: red"
                  >修改积分抵扣比例将带来店铺积分价值的变化，请谨慎修改</span
                >
              </div>
            </a-form-item>

            <div class="card">
              <p class="card__title">积分使用规则</p>
            </div>
            <a-form-item label="积分支付限制">
              <div>
                <a-radio-group name="radioGroup" :default-value="1">
                  <a-radio :value="1"> 不限制 </a-radio>
                  <a-radio :value="2"> 自定义 </a-radio>
                </a-radio-group>
              </div>
            </a-form-item>
            <a-form-item label="积分抵扣比例">
              <div class="flex">
                <span style="margin-right: 10px;">用户可使用积分抵扣订单折扣金额的</span> <a-input class="w120"></a-input>
                <span>%，不可使用积分支付</span>
                <span style="opacity: .6;margin-left: 10px;">不填默认积分抵扣订单折后金额的50%</span>
              </div>
              <div class="flex">
                <span style="margin-right: 10px;">订单折后金额是否包含运费</span>
                <a-radio-group name="radioGroup" :default-value="1">
                  <a-radio :value="1"> 包含运费 </a-radio>
                  <a-radio :value="2"> 不包含运费 </a-radio>
                </a-radio-group>
              </div>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="config__btns">
      <a-button type="primary">保存</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberPoints",
  data() {
    return {
      form: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.card {
  background: #dddddd;
  padding: 10px 10px;
  opacity: 0.9;
  margin-bottom: 10px;
  > p {
    margin: 0;
  }
  &__title {
    border-left: 2px solid #1890ff;
    padding-left: 10px;
    font-weight: 600;
  }
  &__content {
    margin-bottom: 10px;
    > div {
      padding: 10px;
      display: flex;
      align-items: center;
      > div {
        opacity: 0.8;
      }
    }
  }
}
.line {
  padding: 10px;
  > span {
    margin-right: 20px;
    font-weight: 700;
  }
  &__desc {
    margin: 0 20px;
    font-weight: 600;
  }
  &__tip {
    opacity: 0.7;
  }
}
.config {
  position: relative;
  padding-bottom: 60px;
  &__btns {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 60px;
    border-top: 1px solid #dddddd;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
}
</style>

